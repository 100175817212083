





































































import { Vue, Mixins, Component } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { VoucherNumberDialogProperties } from "./VoucherNumberDialogView.vue";
import { VoucherNumberCustomerProperties } from "./VoucherNumberCustomerView.vue";
import { DateFilterDialogProperties } from '@/components/shared/DateFilterDialogView.vue';
import AutoNumeric, { Options } from "autonumeric";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import VoucherService from "@/services/VoucherService";
import EventManager from "@/utilities/EventManager";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const VoucherMainView = () => import("./VoucherMainView.vue");
const VoucherNumberView = () => import("./VoucherNumberView.vue");
const VoucherNumberDialogView = () => import("./VoucherNumberDialogView.vue");
const VoucherNumberCustomerView = () => import("./VoucherNumberCustomerView.vue");
const DateFilterDialogView = () => import("@/components/shared/DateFilterDialogView.vue");

class VoucherInfoProperties {
    voucher: any = {};
    saving: boolean = false;
    events = new EventManager();
}

export { VoucherInfoProperties };

@Component({
    components: {
        EditorControlView,
        AlertDialogView,
        VoucherMainView,
        VoucherNumberView,
        VoucherNumberDialogView,
        VoucherNumberCustomerView,
        DateFilterDialogView
    },
    data: () => ({
        tab: null
    }),
})
export default class VoucherInfoView extends Mixins(InfoViewMixin) {
    private properties = new VoucherInfoProperties();
    private alertDialog = new AlertDialogProperties();
    private editorControl = new EditorControlProperties();
    private voucherNumberDialog = new VoucherNumberDialogProperties();
    private voucherNumberCustomer = new VoucherNumberCustomerProperties();
    private dateFilterDialog = new DateFilterDialogProperties();
    private voucherService = new VoucherService();

    public mounted() {
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            this.load();
        }
    }

    public async load() {
        try {
            if (this.isCreate()) {
                this.properties.voucher = {
                    target: "Overall",
                    discountAmount: 0,
                    discountPercent: 0,
                    discountMaximum: 0,
                    minimumOrderAmount: 0,
                    maximumOrderAmount: 0,
                };
                this.$data.tab = 0;
            } else if (this.isModify()) {
                var id = this.$route.query.id;
                const r = await this.voucherService.get({ id });
                if (r.data.vouchers.length === 0) {
                    throw new Error(this.$t("message.record-not-found").toString());
                }

                this.properties.voucher = r.data.vouchers[0];
                this.$data.tab = 0;
            }

            await this.properties.events.fire('update-formatter');
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async create() {
        await this.$router.push("/management/sales/vouchers/create");
        this.mode = "create";
        await this.load();
    }

    public async save() {
        if (this.properties.saving) {
            return;
        }

        this.properties.saving = true;
        try {
            if (this.isCreate()) {
                const r = await this.voucherService.post(this.properties.voucher);
                this.properties.voucher = r.data;
                await this.$router.push("/management/sales/vouchers/modify?id=" + this.properties.voucher.id);
                this.mode = "modify";

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            } else if (this.isModify()) {
                const r = await this.voucherService.put(this.properties.voucher);
                this.properties.voucher = r.data;

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public cancel() {
        if (this.properties.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async remove() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            await this.voucherService.delete(this.$data.voucher);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "create") {
            await this.create();
        } else if (button === "save") {
            await this.save();
        } else if (button === "cancel") {
            this.cancel();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public async filteredDate() {
        await this.properties.events.fire('filtered-date');
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "discard-changes" && button === this.$t("text.yes")) {
                await this.load();
            } else if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }
        await this.properties.events.fire('alert-dialog-clicked', button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted") {
                await this.$router.push("/management/sales/vouchers");
            }
        }
    }
}
