
















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { VoucherInfoProperties } from './VoucherInfoView.vue';
import { VoucherNumberCustomerProperties } from './VoucherNumberCustomerView.vue';
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import ExceptionUtil from "@/utilities/ExceptionUtil";
import VoucherNumberService from "@/services/VoucherNumberService";
import DatePickerInputView from "@/components/shared/DatePickerInputView.vue";
import { Options } from 'autonumeric';
import CommonUtil from '@/utilities/CommonUtil';
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

class VoucherNumberDialogProperties {
    isNew: boolean = false;
    voucherNumber: any = {
        customer: {},
        code: null,
        expiresAt: null,
        quantity: 0,
        repeatUsage: false,
        discontinued: false,
        voucherId: 0
    };
    visible: boolean = false;
    saving: boolean = false;
    dateFormat: string = null;
}

export { VoucherNumberDialogProperties };

@Component({
    components: { DatePickerInputView }
})
export default class VoucherNumberDialogView extends Vue {
    @Prop({ default: new VoucherNumberDialogProperties() }) 
    private properties: VoucherNumberDialogProperties;
    @Prop() private voucherInfo: VoucherInfoProperties;
    @Prop() private voucherNumberCustomer: VoucherNumberCustomerProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private voucherNumberService = new VoucherNumberService();
    private inputQuantity: HTMLInputElement;
    
    public get voucherNumber() {
        return this.properties.voucherNumber;
    }

    public get quantityInfinite() {
        return Number(this.voucherNumber.quantity) === 0;
    }

    public set quantityInfinite(v: boolean) {
        if (v) {
            AutoNumericUtil.remove(this.inputQuantity);
            this.voucherNumber.quantity = 0;
        } else {
            this.voucherNumber.quantity = 1;

            this.updateFormatter();
        }
    }

    public get quantity() {
        if (Number(this.voucherNumber.quantity) === 0) {
            return this.$t('text.infinite').toString();
        } else {
            return this.voucherNumber.quantity;
        }
    }

    public set quantity(v: string) {
        if (v) this.voucherNumber.quantity = v;
    }
    
    public mounted() {
        this.voucherNumberCustomer.events.subscribe('selected', this.selectedCustomer);

        var dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputQuantity = dialog.$el.querySelector('#quantity');

            this.updateFormatter();
        }
    }

    public destroyed() {
        this.voucherNumberCustomer.events.remove('selected', this.selectedCustomer);
    }

    public updateFormatter() {
        setTimeout(() => {
            const voucherNumber = this.voucherNumber;
            if (voucherNumber) {
                const options: Options = {
                    minimumValue: "0",
                    historySize: 0,
                    decimalPlaces: 0,
                    outputFormat: "number",
                    digitGroupSeparator: "",
                    unformatOnHover: false,
                    unformatOnSubmit: false,
                };

                if (!this.quantityInfinite) {
                    AutoNumericUtil.findOrNew(this.inputQuantity, null, options)
                        .set(voucherNumber.quantity ?? 0);
                }
            }
        }, 150);
    }

    public selectCustomer() {
        this.voucherNumberCustomer.visible = true;
    }

    public selectedCustomer(customer: any) {
        customer.text = `${customer.code} - ${customer.name}`;
        this.voucherNumber.customer = customer;
        this.voucherNumber.customerId = customer.id;
        this.voucherNumberCustomer.visible = false;
    }

    public customerCleared() {
        this.voucherNumber.customer = {};
        this.voucherNumber.customerId = null;
    }

    public expiresAtCleared() {
        this.voucherNumber.expiresAt = null;
    }

    public async generate() {
        try {
            const r = await this.voucherNumberService.generate();
            this.properties.voucherNumber.code = r.data;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = false;
            }
        }
    }

    public async save() {
        this.properties.saving = true;

        try {
            const voucherNumber = CommonUtil.clone(this.voucherNumber);
            voucherNumber.customer = null;

            if (this.properties.isNew) {
                voucherNumber.voucherId = this.voucherInfo.voucher.id;
                await this.voucherNumberService.post(voucherNumber);
            } else {
                await this.voucherNumberService.put(voucherNumber);
            }

            await this.voucherInfo.events.fire('load-numbers');
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public close() {
        if (!this.properties.saving) {
            this.properties.visible = false;
        }
    }
}
