import AbstractService from "./AbstractService";
import Axios from "axios";

export default class VoucherNumberService extends AbstractService {
    private controller = "voucher-numbers";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(voucherNumber: any) {
        const d = { voucherNumber };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(voucherNumber: any) {
        const d = { voucherNumber };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(voucherNumber: any) {
        const d = { voucherNumber };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }

    public generate() {
        const url = this.makeUrl(`${this.controller}/generate`);
        return Axios.get<any>(url, this.makeConfig(false));
    }
}